import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";

interface LinkProps {
  className?: string;
  activeClassName?: string;
  type: string;
  link?: {
    linkType?: string;
    linkCopy?: string;
    internalLink?: {
      link: {};
      section?: {
        slug: {
          current: string;
        };
      };
    };
    externalLink?: {
      link: string;
      targetBlank: boolean;
    };
  };
  to?: string;
  href?: string;
  ariaLabel?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
  children?: any;
}
export const Link = ({
  className,
  activeClassName = "active",
  type,
  link,
  to,
  href,
  ariaLabel,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseOver,
  children,
}: LinkProps) => {
  switch (type) {
    case "internalLink":
      let target = to
        ? to
        : link &&
          link.internalLink &&
          getInternalLinkPath(link.internalLink.link);

      if (link && link.internalLink && link.internalLink.section) {
        target += `#${link.internalLink.section.slug.current}`;
      }

      return (
        <GatsbyLink
          type={type}
          className={className}
          activeClassName={activeClassName}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          to={target}
          aria-label={ariaLabel}
        >
          {children}
        </GatsbyLink>
      );
    case "externalLink":
      return (
        <a
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          href={
            href ? href : link && link.externalLink && link.externalLink.link
          }
          target={
            link && link.externalLink && !link.externalLink.targetBlank
              ? ""
              : "_blank"
          }
          rel="noopener noreferrer"
          aria-label={ariaLabel}
        >
          {children}
        </a>
      );

    default:
      return null;
  }
};
