import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Link } from "src/components/link";
import { LineItem } from "src/components/cart/line-item";
import { Price } from "src/components/price";

import {
  useCartTotals,
  useCartItems,
  useCheckout,
  useToggleCart,
} from "src/context/siteContext";

import styles from "./view.module.css";

export const CartView = () => {
  const lineItems = useCartItems();
  const openCheckout = useCheckout();
  const toggleCart = useToggleCart();
  const totals = useCartTotals();
  const { subtotal, total } = totals;

  const handleCheckoutClick = () => {
    openCheckout();
  };
  return (
    <div className={styles.cartView}>
      <div className={styles.inner}>
        {lineItems.length < 1 && (
          <h4 className={cx("body body--large medium", styles.emptyMessaging)}>
            Your bag is empty.
          </h4>
        )}
        {lineItems.length > 0 && (
          <div className={styles.lineItems}>
            {lineItems.map((item: { id: string; quantity: number }) => (
              <LineItem {...item} key={item.id + item.quantity} />
            ))}
          </div>
        )}
        <div className={styles.footer}>
          {lineItems.length > 0 && (
            <div>
              <div className="x f jcb aib">
                <span className="">Subtotal</span>
                <span className="">
                  <Price defaultPrice={subtotal} />
                </span>
              </div>
            </div>
          )}
          {lineItems.length < 1 ? (
            <Link
              className={cx("cta cta--secondary", styles.shopCta)}
              onClick={() => toggleCart()}
              type="internalLink"
              to="/shop"
            >
              Shop now
            </Link>
          ) : (
            <button
              onClick={() => handleCheckoutClick()}
              type="submit"
              className={cx("cta cta--primary", styles.checkoutButton)}
            >
              <span>Checkout</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
