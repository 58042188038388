import React, { useState, useEffect } from "react";

import { useNewsletterData } from "src/hooks/useNewsletterPopupData";

import cx from "classnames";
import { subscribe } from "klaviyo-subscribe";
import cookie from "js-cookie";

import "./newsletter.css";

const NewsletterPopup = () => {
  const [success, setSuccess] = useState(false);
  const [selectedMan, setSelectedMan] = useState(false);
  const [selectedWoman, setSelectedWoman] = useState(false);
  const [shouldShowNewsletterPopup, setShouldShowNewsletterPopup] =
    useState(false);

  const { showNewsletter } = useNewsletterData();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;
    const { email } = form.elements;

    if (!email.value) return;

    try {
      let genderOption = "Unspecified";

      if (selectedMan && selectedWoman) {
        genderOption = "Man, Woman";
      } else if (selectedMan) {
        genderOption = "Man";
      } else if (selectedWoman) {
        genderOption = "Woman";
      } else {
        genderOption = "Unspecified";
      }

      await subscribe("S6Hvcc", email.value, {
        $fields: ["Gender"],
        Gender: genderOption,
      });

      setSuccess(true);
      cookie.set("dv_newsletter-popup", "true", { expires: 31 });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const hasSeenNewsletter =
      cookie.get("dv_newsletter-popup") || cookie.get("__kla_id");

    if (showNewsletter && !hasSeenNewsletter) {
      setTimeout(() => {
        setShouldShowNewsletterPopup(true);
      }, 5000);
    }
  }, [showNewsletter]);

  if (!showNewsletter) return null;

  return (
    <div
      className={cx(
        "newsletter-popup",
        shouldShowNewsletterPopup ? "show" : ""
      )}
    >
      <button
        className="newsletter-popup__close"
        onClick={() => {
          setShouldShowNewsletterPopup(false);
          cookie.set("dv_newsletter-popup", "true", { expires: 31 });
        }}
      >
        X Close
      </button>

      {!success ? (
        <form onSubmit={handleSubmit} className="newsletter-popup__form">
          <p>
            Enter your email to receive news about District Vision product
            launches, events and sales.
          </p>
          <label>
            <input
              name="email"
              className="newsletter-popup__email"
              placeholder="Your email address"
              type="email"
              required={true}
              aria-label="Your email address"
            />
          </label>
          <div className="newsletter-popup__options">
            <div className="f aic mt1 account__form-check-wrapper account__form-check-wrapper--newsletter">
              <div className="account__form-check account__form-check--newsletter">
                <input
                  type="checkbox"
                  id="man"
                  name="man"
                  className="account__form-checkbox-input account__form-checkbox-input--newsletter"
                  onChange={(e) => {
                    setSelectedMan(e.target.checked);
                  }}
                />
                <span className="account__form-checkbox-mark account__form-checkbox-mark--newsletter" />
              </div>
              <label htmlFor="man">Man</label>
            </div>
            <div className="f aic mt1 account__form-check-wrapper account__form-check-wrapper--newsletter">
              <div className="account__form-check account__form-check--newsletter">
                <input
                  type="checkbox"
                  id="woman"
                  name="woman"
                  className="account__form-checkbox-input account__form-checkbox-input--newsletter"
                  onChange={(e) => {
                    setSelectedWoman(e.target.checked);
                  }}
                />
                <span className="account__form-checkbox-mark account__form-checkbox-mark--newsletter" />
              </div>
              <label htmlFor="woman">Woman</label>
            </div>
          </div>
          <button type="submit" className="newsletter-popup__submit">
            <span>Submit</span>
          </button>
        </form>
      ) : (
        <p>
          Thank you!
          <br />
          Please check your email to confirm your subscription.
        </p>
      )}
    </div>
  );
};

export default NewsletterPopup;
