import React, { useState } from "react";
import cx from "classnames";
import { subscribe } from "klaviyo-subscribe";

import styles from "./signup.module.css";

export const Signup = ({
  className,
  listId = "S6Hvcc",
  customFields = {},
  buttonText = "Submit",
  message = `Please Check your email to confirm your subscription.`,
  messageClassName,
}: {
  className?: string;
  listId?: string;
  customFields?: {};
  buttonText?: string;
  message?: string;
  messageClassName?: string;
}) => {
  const [success, setSuccess] = useState(false);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;
    const { email } = form.elements;
    subscribe(listId, email.value, customFields).then(() => {
      form.reset();
      setSuccess(true);

      // fire Facebook pixel event
      window?.fbq && window.fbq("trackCustom", "NewsletterSignup");
    });
  };
  return (
    <div className={cx(styles.signup, className)}>
      {!success ? (
        <form onSubmit={(e) => handleSubmit(e)} className={styles.form}>
          <label>
            Email
            <input
              name="email"
              className={cx(styles.emailInput)}
              placeholder="Your email address"
              type="email"
              required={true}
              aria-label="Your email address"
            />
          </label>
          <button type="submit" className={styles.submitButton}>
            <span>{buttonText}</span>
          </button>
        </form>
      ) : (
        <p className={cx(styles.message, messageClassName)}>
          Thank you!
          <br />
          Please check your email to confirm your subscription.
        </p>
      )}
    </div>
  );
};
