import React from "react";
import * as styles from "./product-title.module.css";
import CourseIcon from './CourseIcon';

import cx from "classnames";

export const ProductTitle = ({
  tag: Tag = "span",
  courseType,
  title,
  className,
}: {
  tag: keyof JSX.IntrinsicElements;
  title: string;
  courseType: string;
  className?: string;
}) => {
  return (
    <React.Fragment>
      <Tag
        // style={{ color: courseType ? "var(--black)" : "var(--gray)" }}
        className={className}
      >
        {title.includes(", ") &&
          title.split(", ").map((segment, i) => {
            return i === 0 ? (
              <span key={i} className={styles.titleSegment}>
                {segment}
              </span>
            ) : (
              <div className={styles.titleSegmentCourse}>
                <span key={i} className={styles.titleSegment}>
                  {segment}
                </span>
                {courseType && <CourseIcon courseType={courseType} />}
              </div>
            );
          })}
        {!title.includes(", ") && (
          <div className={styles.titleSegmentCourse}>
            <span className={styles.titleSegment}>{title}</span>
            {courseType && <CourseIcon courseType={courseType} />}
          </div>
        )}
      </Tag>
    </React.Fragment>
  );
};
