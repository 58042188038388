/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import { StoreContextProvider } from "src/context/siteContext";
import { Initialize } from "src/components/analytics";

// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== "production") {
//     const whyDidYouRender = require("@welldone-software/why-did-you-render");
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//     });
//   }
// };

// hide Zendesk widget by default
window.zE &&
  window.zE(function () {
    window.zE.hide();
  });

if (window.ga) {
  window.ga("require", "linker");
  window.ga("linker:autoLink", [
    "districtvision.com",
    "checkout.districtvision.com",
    "districtvision.myshopify.com",
  ]);
}

window.dataLayer = window.dataLayer || [];

const analytics = Initialize({
  // Setup analytics
  facebookPixelId: process.env.GATSBY_FB_PIXEL_ID,
  googleAnalyticsPropertyId: process.env.GATSBY_GA_ID,
  googleLinkerDomains: [
    "districtvision.com",
    "checkout.districtvision.com",
    "districtvision.myshopify.com",
  ],
});

window.analytics = analytics;

export const onRouteUpdate = ({ location, prevLocation }) => {
  analytics.pageView(location);

  if (prevLocation !== null) {
    const skipLink = document.querySelector("#reach-skip-nav");
    if (skipLink) {
      skipLink.focus();
    }
  }
};

export const wrapRootElement = ({ element }) => (
  <StoreContextProvider>{element}</StoreContextProvider>
);
