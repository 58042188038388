import React from "react";
import cx from "classnames";
import { MenuItem } from "src/components/menu-item";
import { Link } from "src/components/link";
import { Logotype } from "src/components/logotype";

import {
  useStoreContext,
  useToggleCart,
  useToggleMenu,
  useToggleSubmenu,
  useSetActiveSubmenu,
  useToggleNewsletter,
} from "src/context/siteContext";
import { useFooterData } from "src/hooks/useFooterData";

import styles from "./footer.module.css";

export const FooterLogo = () => {
  const { brandTagline } = useFooterData();
  return (
    <div className={styles.logoBlurb}>
      <Link type="internalLink" to="/">
        <Logotype />
      </Link>
      <p className={styles.blurb}>{brandTagline}</p>
    </div>
  );
};

export const Footer = () => {
  const {
    menuIsOpen,
    submenuIsOpen,
    cartIsOpen,
    newsletterIsOpen,
    activeSubmenu,
  } = useStoreContext();
  const toggleCart = useToggleCart();
  const toggleMenu = useToggleMenu();
  const toggleSubmenu = useToggleSubmenu();
  const toggleNewsletter = useToggleNewsletter();
  const setActiveSubmenu = useSetActiveSubmenu();
  const { menu } = useFooterData();

  const handleClick = (event: MouseEvent, item: any) => {
    menuIsOpen && toggleMenu();
    cartIsOpen && toggleCart();
    newsletterIsOpen && toggleNewsletter();

    switch (item.type) {
      case "submenu":
        setActiveSubmenu(item.submenu);
        if (
          !submenuIsOpen ||
          (activeSubmenu && activeSubmenu.id === item.submenu.id)
        ) {
          toggleSubmenu();
        }
        break;
      case "newsletter":
        toggleNewsletter();
        break;
      case "zendesk":
        submenuIsOpen && toggleSubmenu();
        window.zE && window.zE.activate({ hideOnClose: true });
        break;
      case "internalLink":
        submenuIsOpen && toggleSubmenu();
      default:
    }
  };

  return (
    <div className={styles.footer}>
      {/* <FooterLogo /> */}
      <div className={cx(styles.menuCopywrite)}>
        <ul className={styles.menuList}>
          {menu.items &&
            menu.items.map((item: any) => (
              <MenuItem
                className={cx(
                  styles.menuItem,
                  item.spaceAfter ? styles.spaceAfter : ""
                )}
                item={item}
                key={item._key}
                clickHandler={(event: MouseEvent) => handleClick(event, item)}
              >
                <span className="underline-on-hover">{item.title}</span>
              </MenuItem>
            ))}
        </ul>
        <p className={cx(styles.copywrite)}>
          &copy; District Vision {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};
