import React from "react";
import cx from "classnames";

import * as styles from "./backdrop.module.css";

export const Backdrop = ({
  active,
  toggle,
  course,
}: {
  active: boolean;
  toggle: () => void;
  course: boolean;
}) => {
  return (
    <div
      aria-hidden="true"
      className={cx(
        styles.backdrop,
        active ? styles.active : "",
        course ? styles.course : ""
      )}
      onClick={toggle}
    />
  );
};
