import { useStaticQuery, graphql } from "gatsby";
export const useFooterData = () => {
  const { sanityFooter } = useStaticQuery(
    graphql`
      query FooterQuery {
        sanityFooter {
          brandTagline
          menu: _rawMenu(resolveReferences: { maxDepth: 9 })
        }
      }
    `
  );

  return sanityFooter;
};
