import React from "react";
import Helmet from "react-helmet";
import fetch from "unfetch";

//
// === Initialize global Analytics ===
//

const now = (new Date().getTime() / 1000).toFixed(0);

export const Initialize = () => {
  const getIpAddress = async () => {
    const request = await fetch("https://api.ipify.org/?format=json");
    const { ip } = await request.json();
    return ip;
  };

  const currentLocation =
    typeof window !== undefined &&
    process.env.NODE_ENV !== "development" &&
    window.location.href;

  const pageView = async (location: any) => {
    global.fbq && global.fbq("track", "PageView");

    const clientIp = await getIpAddress();

    try {
      await fetch(`/.netlify/functions/conversions`, {
        method: "POST",
        body: JSON.stringify({
          data: [
            {
              event_name: "PageView",
              event_time: now,
              event_source_url: location.href,
              user_data: {
                client_ip_address: clientIp,
              },
            },
          ],
        }),
      });
    } catch (error) {
      console.warn("error", error);
    }
  };

  const productView = async (product = {}) => {
    window.lintrk && window.lintrk("track", { conversion_id: 12828994 });

    global.gtag && global.gtag("event", "view_item", { items: [product] });

    // facebook ViewContent
    global.fbq &&
      global.fbq("track", "ViewContent", {
        content_name: product.title,
        content_ids: [product.id],
        content_type: "product",
        value: product.price,
        currency: "USD",
      });

    global.dataLayer &&
      global.dataLayer.push({
        event: "viewItem",
        productID: product.id,
        product_name: product.title,
        product_price: product.price,
        currency: "USD",
      });

    const clientIp = await getIpAddress();

    try {
      currentLocation &&
        (await fetch(`/.netlify/functions/conversions`, {
          method: "POST",
          body: JSON.stringify({
            data: [
              {
                event_name: "ViewContent",
                event_time: now,
                event_source_url: currentLocation,
                user_data: {
                  client_ip_address: clientIp,
                },
                custom_data: {
                  content_name: product.title,
                  content_ids: [product.id],
                  content_type: "product",
                  value: product.price,
                  currency: "USD",
                },
              },
            ],
          }),
        }));
    } catch (error) {
      console.warn(error);
    }

    // klaviyo view product
    var _learnq = _learnq || [];

    var item = {
      Name: product.title,
      ProductID: product.id,
      Categories: product.category,
      ImageURL: product.imageUrl,
      URL: product.productUrl,
      Price: product.price,
      CompareAtPrice: product.compareAtPrice,
    };

    _learnq.push(["track", "Viewed Product", item]);
    _learnq.push([
      "trackViewedItem",
      {
        Title: item.Name,
        ItemId: item.ProductID,
        ImageUrl: item.ImageURL,
        Url: item.URL,
        Metadata: {
          Price: item.Price,
          CompareAtPrice: item.CompareAtPrice,
        },
      },
    ]);
  };

  const addToCart = async (product = {}) => {
    global.gtag && global.gtag("event", "add_to_cart", { items: [product] });
    global.fbq &&
      global.fbq("track", "AddToCart", {
        content_name: product.title,
        content_category: product.category,
        content_ids: [product.id],
        content_type: "product",
        value: product.price,
        currency: "USD",
      });

    global.dataLayer &&
      global.dataLayer.push({
        event: "addTocart",
        productID: product.id,
        product_name: product.title,
        product_price: product.price,
        currency: "USD",
      });

    window.lintrk && window.lintrk("track", { conversion_id: 12828978 });

    const clientIp = await getIpAddress();

    try {
      currentLocation &&
        (await fetch(`/.netlify/functions/conversions`, {
          method: "POST",
          body: JSON.stringify({
            data: [
              {
                event_name: "AddToCart",
                event_time: now,
                event_source_url: currentLocation,
                user_data: {
                  client_ip_address: clientIp,
                },
                custom_data: {
                  content_name: product.title,
                  content_category: product.category,
                  content_ids: [product.id],
                  content_type: "product",
                  value: product.price,
                  currency: "USD",
                },
              },
            ],
          }),
        }));
    } catch (error) {
      console.warn(error);
    }

    // klaviyo
    var _learnq = _learnq || [];

    var item = {
      Name: product.title,
      ProductID: product.id,
      Categories: product.type,
      ImageURL: product.imageUrl,
      URL: product.productUrl,
      Price: product.defaultPrice,
      CompareAtPrice: product.compareAtPrice,
    };

    _learnq.push(["track", "Added to Cart", item]);
  };

  const removeFromCart = (item = {}) => {
    global.gtag && global.gtag("event", "remove_from_cart", { items: [item] });
  };

  const initiateCheckout = async () => {
    global.gtag && global.gtag("event", "Initiate Checkout");
    global.fbq && global.fbq("track", "InitiateCheckout");

    window.lintrk && window.lintrk("track", { conversion_id: 12828986 });

    const clientIp = await getIpAddress();

    try {
      currentLocation &&
        (await fetch(`/.netlify/functions/conversions`, {
          method: "POST",
          body: JSON.stringify({
            data: [
              {
                event_name: "InitiateCheckout",
                event_time: now,
                event_source_url: currentLocation,
                user_data: {
                  client_ip_address: clientIp,
                },
              },
            ],
          }),
        }));
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    pageView,
    productView,
    addToCart,
    removeFromCart,
    initiateCheckout,
  };
};

//
// === Group all script tags here` ===
//

export default ({
  facebookPixelId,
  klaviyoId,
}: {
  facebookPixelId?: string;
  klaviyoId?: string;
}) => (
  <Helmet>
    {/* Facebook */}
    {facebookPixelId && (
      <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${facebookPixelId}');
        `}</script>
    )}
    {facebookPixelId && (
      <noscript>{`
          <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1"
          />
        `}</noscript>
    )}
    {klaviyoId && (
      <script
        type="application/javascript"
        async
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoId}`}
      ></script>
    )}
    {/* LinkedIn */}
    <script type="text/javascript">
      {`
       _linkedin_partner_id = "4894314";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      `}
    </script>
    <script type="text/javascript">
      {`
       (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);
        })(window.lintrk);
      `}
    </script>
  </Helmet>
);
