import React from "react";

export default ({courseType}) => {
  return courseType === "audio" ? (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.5 11V2L3.5 5H1V8H3.5L6.5 11Z" stroke="currentColor" />
      <path d="M8.5 4L8.5 9" stroke="currentColor" />
      <path d="M10.5 3L10.5 10" stroke="currentColor" />
    </svg>
  ) : (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 3L7 1" stroke="currentColor" />
      <path d="M3 1L5 3" stroke="currentColor" />
      <rect x="1" y="10" width="8" height="1" fill="currentColor" />
      <rect x="1" y="3" width="8" height="1" fill="currentColor" />
      <rect
        x="1"
        y="4"
        width="6"
        height="1"
        transform="rotate(90 1 4)"
        fill="currentColor"
      />
      <rect
        x="10"
        y="4"
        width="6"
        height="1"
        transform="rotate(90 10 4)"
        fill="currentColor"
      />
      <path d="M1 3L0 4H1V3Z" fill="currentColor" />
      <path d="M1.19249e-08 10L1 11L1 10L1.19249e-08 10Z" fill="currentColor" />
      <path d="M9 11L10 10L9 10L9 11Z" fill="currentColor" />
      <path d="M10 4L9 3L9 4L10 4Z" fill="currentColor" />
      <path
        d="M7.1214 7.70709L5.70718 6.29288L4.29297 7.70709L2.87876 6.29288"
        stroke="currentColor"
      />
    </svg>
  );
};
