import { useStaticQuery, graphql } from "gatsby";
export const useNewsletterData = () => {
  const { sanityNewsletter } = useStaticQuery(
    graphql`
      query MyQuery {
        sanityNewsletter {
          showNewsletter
          womanList
          manList
        }
      }
    `
  );

  return sanityNewsletter;
};
