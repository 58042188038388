import React, { useState, useEffect } from "react";
import { useActiveCurrency } from "src/context/siteContext";

const SHOPIFY_CHECKOUT_CURRENCY = "shopify_checkout_currency";

interface PriceProps {
  defaultPrice: any;
  presentmentPrices?: any;
  multiplier?: number;
  showDiscount?: boolean;
  variantId?: number;
}

export const Price = ({
  defaultPrice,
  presentmentPrices,
  multiplier = 1,
  showDiscount = false,
  variantId,
}: PriceProps) => {
  const activeCurrency = useActiveCurrency();
  const [price, setPrice] = useState(
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency:
        activeCurrency ||
        (typeof window !== "undefined" &&
          localStorage.getItem(SHOPIFY_CHECKOUT_CURRENCY)) ||
        "USD",
    }).format(Number.parseFloat(defaultPrice || defaultPrice.amount)) || {
      amount: "0.00",
    }
  );
  const [compareAtPrice, setCompareAtPrice] = useState<null | string>(null);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);

  useEffect(() => {
    if (presentmentPrices) {
      const livePrice =
        presentmentPrices &&
        presentmentPrices.find(
          (item: any) => item.price.currencyCode === activeCurrency
        );

      const livePriceNumber =
        livePrice && Number.parseFloat(livePrice.price.amount);

      livePrice &&
        setPrice(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: activeCurrency || "USD",
          }).format(livePriceNumber * multiplier)
        );

      const liveCompareAtPrice =
        presentmentPrices &&
        presentmentPrices.find(
          (item: any) => item.compareAtPrice?.currencyCode === activeCurrency
        );

      const liveCompareAtPriceNumber =
        liveCompareAtPrice &&
        Number.parseFloat(liveCompareAtPrice.compareAtPrice?.amount);

      liveCompareAtPrice &&
        liveCompareAtPriceNumber > livePriceNumber &&
        setCompareAtPrice(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: activeCurrency || "USD",
          }).format(liveCompareAtPriceNumber * multiplier)
        );

      livePrice &&
        liveCompareAtPrice &&
        setDiscountPercentage(
          ((liveCompareAtPriceNumber - livePriceNumber) /
            liveCompareAtPriceNumber) *
            100
        );
    } else {
      // setPrice(
      //   new Intl.NumberFormat("en-US", {
      //     style: "currency",
      //     currency: activeCurrency || "USD",
      //   }).format(Number.parseFloat(defaultPrice) * multiplier)
      // );
      setPrice(defaultPrice?.amount || defaultPrice);
    }
  }, [activeCurrency, defaultPrice, presentmentPrices, multiplier]);

  return (
    <>
      ${price.replace(".00", "") || price.replace(".0", "")}
      {compareAtPrice && (
        <span className="strike-through ml025">
          $
          {compareAtPrice?.replace(".00", "") ||
            compareAtPrice?.replace(".0", "")}
        </span>
      )}
      {showDiscount && discountPercentage > 0 && (
        <span className="ml025 upper">
          {discountPercentage.toFixed(0)}% off
        </span>
      )}
    </>
  );
};
