import React, { useState } from "react";
import cx from "classnames";
import { Modal, Fade } from "@material-ui/core";
import { Backdrop } from "src/components/backdrop";
import { Link } from "src/components/link";
import Div100vh from "src/components/div100vh";

import { MenuItem } from "src/components/menu-item";
import {
  useStoreContext,
  useToggleMenu,
  useSetActiveSubmenu,
  useToggleNewsletter,
  useToggleCart,
} from "src/context/siteContext";
import { getProductCount } from "src/utils/getProductCount";

import * as styles from "./mobile-menu.module.css";

interface MobileMenuProps {
  menuItems: [
    {
      _key: string;
      title: string;
      type: string;
      spaceAfter?: boolean;
    }
  ];
}

export const MobileMenu = ({ menuItems }: MobileMenuProps) => {
  const {
    menuIsOpen,
    cartIsOpen,
    newsletterIsOpen,
    noticeBarVisible,
    activeSubmenu,
  } = useStoreContext();
  const toggleMenu = useToggleMenu();
  const toggleCart = useToggleCart();
  const toggleNewsletter = useToggleNewsletter();
  const setActiveSubmenu = useSetActiveSubmenu();

  const handleMenuItemClick = (event: MouseEvent, item: any) => {
    cartIsOpen && toggleCart();
    newsletterIsOpen && toggleNewsletter();

    switch (item.type) {
      case "submenu":
        setActiveSubmenu(item.submenu);
        break;
      case "newsletter":
        menuIsOpen && toggleMenu();
        toggleNewsletter();
        break;
      case "zendesk":
        menuIsOpen && toggleMenu();
        window.zE &&
          window.zE(function () {
            window.zE.activate({ hideOnClose: true });
          });
        break;
      default:
        menuIsOpen && toggleMenu();
    }
  };

  // TODO: Currently hard-coding '/account' Link and will eventually fix this via CMS

  return (
    <Modal
      anchor="left"
      open={menuIsOpen}
      onClose={() => toggleMenu()}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{ active: menuIsOpen, toggle: toggleMenu }}
    >
      <Fade
        in={menuIsOpen}
        timeout={{
          enter: 600,
          exit: 600,
        }}
      >
        <Div100vh
          className={cx(
            styles.drawer,
            noticeBarVisible ? styles.noticeBarVisible : ""
          )}
          role="presentation"
        >
          <div className={styles.drawerInner}>
            <ul
              className={cx(
                styles.menuList,
                activeSubmenu ? styles.submenuOpen : ""
              )}
              role="menu"
            >
              {menuItems &&
                menuItems.map((item, index) => (
                  <React.Fragment>
                    <MenuItem
                      className={styles.menuItem}
                      item={item}
                      key={item._key}
                      activeSubmenu={activeSubmenu || null}
                      setActiveSubmenu={setActiveSubmenu}
                      clickHandler={(event: MouseEvent) =>
                        handleMenuItemClick(event, item)
                      }
                    >
                      <span className="underline-on-hover">{item.title}</span>
                    </MenuItem>
                    {index === menuItems.length - 1 && (
                      <li className={cx(styles.menuItem)}>
                        <Link
                          to="/account"
                          type="internalLink"
                          onClick={() => {
                            menuIsOpen && toggleMenu();
                          }}
                        >
                          Account
                        </Link>
                      </li>
                    )}
                  </React.Fragment>
                ))}
            </ul>
            <div className={styles.submenu}>
              {activeSubmenu &&
                activeSubmenu.sections.map((section: any) => {
                  return (
                    <ul key={section._key} role="menu">
                      {section.items.map((item: any) => {
                        const productCount = getProductCount(item);
                        return (
                          <MenuItem
                            className={cx(styles.submenuItem)}
                            item={item}
                            key={item._key}
                            clickHandler={(event) =>
                              handleMenuItemClick(event, item)
                            }
                          >
                            {section.title && (
                              <span className={styles.sectionTitle}>
                                {section.title}
                              </span>
                            )}
                            <span>{item.title}</span>
                            {/* {productCount ? (
                              <span className={styles.productCount}>
                                ({productCount})
                              </span>
                            ) : (
                              ""
                            )} */}
                          </MenuItem>
                        );
                      })}
                    </ul>
                  );
                })}
            </div>
          </div>
        </Div100vh>
      </Fade>
    </Modal>
  );
};
