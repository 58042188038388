import React from "react";
import cx from "classnames";
import { ReactComponent as LogotypeSVG } from "src/images/svg/DistrictVisionLogotype.svg";
import { ReactComponent as CourseLogotypeSVG } from "src/images/svg/DV_Tapes_Wordmark.svg";

import styles from "./logotype.module.css";

export const Logotype = ({
  className,
  isCourse,
}: {
  className?: string;
  isCourse: boolean;
}) => {
  return (
    <div
      className={cx(
        isCourse ? styles.courseLogoType : styles.logotype,
        className
      )}
    >
      {isCourse ? <CourseLogotypeSVG /> : <LogotypeSVG />}
    </div>
  );
};
