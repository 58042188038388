import React, { useState, useEffect, Fragment } from "react";
import { Transition } from "react-transition-group";

import cx from "classnames";
import { MenuItem } from "src/components/menu-item";
import { Link } from "src/components/link";
import { Submenu } from "src/components/submenu";
import {
  CURRENCY_SYMBOLS,
  CurrencySwitcher,
} from "src/components/currency-switcher";

import {
  useStoreContext,
  useCartCount,
  useToggleCart,
  useToggleMenu,
  useRevealNoticeBar,
  useHideNoticeBar,
  useDismissNoticeBar,
  useToggleSubmenu,
  useSetActiveSubmenu,
  useToggleNewsletter,
  useActiveCurrency,
} from "src/context/siteContext";

import { useScrollTop } from "src/hooks/useScrollTop";
import { useHeaderData } from "src/hooks/useHeaderData";
import { MobileMenu } from "src/components/mobile-menu";

import { ReactComponent as DVMark } from "src/images/svg/DistrictVisionMark.svg";

import styles from "./header.module.css";

const NoticeBar = ({ copy, visible }: { copy: string; visible: boolean }) => {
  const dismissNoticeBar = useDismissNoticeBar();

  const handleDismiss = () => {
    dismissNoticeBar();
  };

  const TRANSITION_STYLES = {
    default: {
      transform: `translate3d(0, -100%, 0)`,
      transition: `transform 300ms ease`,
      position: `fixed`,
      top: `0`,
      left: `0`,
      width: `100%`,
      zIndex: `99999`,
    },
    entering: {
      transform: `translate3d(0, -100%, 0)`,
    },
    entered: {
      transform: `translate3d(0, 0, 0)`,
    },
    exiting: {
      transform: `translate3d(0, -100%, 0)`,
    },
    exited: {
      transform: `translate3d(0, -100%, 0)`,
    },
  };

  return (
    <Transition
      in={visible}
      timeout={{
        appear: 0,
        enter: 0,
        exit: 300,
      }}
    >
      {(state) => (
        <div
          style={{
            ...TRANSITION_STYLES.default,
            ...TRANSITION_STYLES[state],
          }}
        >
          <div className={styles.noticeBar}>
            <p className={cx(styles.noticeBarCopy)}>{copy}</p>
            <button
              onClick={handleDismiss}
              className={cx(styles.noticeBarDismiss)}
            >
              –{" "}
              <span className="underline-on-hover underline-on-hover--gray">
                Dismiss
              </span>
            </button>
          </div>
        </div>
      )}
    </Transition>
  );
};

export const Header = () => {
  const {
    menuIsOpen,
    submenuIsOpen,
    cartIsOpen,
    noticeBarVisible,
    noticeBarDismissed,
    newsletterIsOpen,
    activeSubmenu,
    isMediaPlayerOpen,
  } = useStoreContext();
  const count = useCartCount();
  const toggleCart = useToggleCart();
  const toggleMenu = useToggleMenu();
  const toggleSubmenu = useToggleSubmenu();
  const setActiveSubmenu = useSetActiveSubmenu();
  const toggleNewsletter = useToggleNewsletter();
  const revealNoticeBar = useRevealNoticeBar();
  const hideNoticeBar = useHideNoticeBar();
  const activeCurrency = useActiveCurrency();

  const [currencySwitcherOpen, setCurrencySwitcherOpen] = useState(false);
  const { menu, showNoticeBar, noticeBarCopy } = useHeaderData();
  const isTopOfPage = useScrollTop();

  const toggleCurrencySwitcher = () => {
    setCurrencySwitcherOpen(!currencySwitcherOpen);
  };

  const menuItems = menu.items as [
    {
      _key: string;
      title: string;
      type: string;
      spaceAfter?: boolean;
      submenu?: any;
    }
  ];

  useEffect(() => {
    if (showNoticeBar && isTopOfPage && !noticeBarDismissed) {
      revealNoticeBar();
    } else {
      hideNoticeBar();
    }
  }, [showNoticeBar, isTopOfPage]);

  const handleLogoClick = () => {
    menuIsOpen && toggleMenu();
    submenuIsOpen && toggleSubmenu();
    cartIsOpen && toggleCart();
    newsletterIsOpen && toggleNewsletter();
  };

  const handleClick = (event: MouseEvent, item: any) => {
    menuIsOpen && toggleMenu();
    cartIsOpen && toggleCart();
    newsletterIsOpen && toggleNewsletter();
    currencySwitcherOpen && toggleCurrencySwitcher();

    switch (item.type) {
      case "submenu":
        setActiveSubmenu({
          ...item.submenu,
          title: item.title,
        });
        if (
          !submenuIsOpen ||
          (activeSubmenu && activeSubmenu?.id === item.submenu.id)
        ) {
          toggleSubmenu();
        }
        break;
      case "newsletter":
        toggleNewsletter();
        break;
      case "zendesk":
        submenuIsOpen && toggleSubmenu();
        window.zE && window.zE.activate({ hideOnClose: true });
        break;
      case "internalLink":
        submenuIsOpen && toggleSubmenu();
      default:
    }
  };

  const handleMenuToggle = () => {
    cartIsOpen && toggleCart();

    const firstMenuItemWithSubmenu =
      menuItems && menuItems.find((item: any) => item.submenu);

    const defaultSubmenu = firstMenuItemWithSubmenu?.submenu;

    setActiveSubmenu(defaultSubmenu);

    toggleMenu();
  };

  const handleCartToggle = () => {
    menuIsOpen && toggleMenu();
    currencySwitcherOpen && toggleCurrencySwitcher();

    if (submenuIsOpen) {
      setActiveSubmenu(undefined);
      toggleSubmenu();
    }

    toggleCart();
  };

  const handleCurrencyClick = () => {
    if (submenuIsOpen) {
      setActiveSubmenu(undefined);
      toggleSubmenu();
    }
    menuIsOpen && toggleMenu();
    cartIsOpen && toggleCart();
    newsletterIsOpen && toggleNewsletter();
    toggleCurrencySwitcher();
  };

  const helpItem = menuItems.find((item) => item.title === "Help");
  const filteredMenuItems = menuItems.filter(({ title }) => title !== "Help");

  return (
    <div className={noticeBarVisible ? styles.noticeBarVisible : ""}>
      <NoticeBar
        copy={noticeBarCopy}
        visible={!noticeBarDismissed && noticeBarVisible && !isMediaPlayerOpen}
      />
      <div
        className={isMediaPlayerOpen ? styles.mediaPlayerOpen : styles.header}
      >
        <div className={styles.headerInner}>
          <div className={styles.headerLogo}>
            <Link
              type="internalLink"
              to="/"
              onClick={() => handleLogoClick()}
              ariaLabel="District Vision"
            >
              <DVMark />
            </Link>
          </div>
          <ul
            className={cx(
              styles.headerMenu,
              submenuIsOpen ? styles.submenuOpen : "",
              cartIsOpen ? styles.cartOpen : "",
              currencySwitcherOpen ? styles.currencySwitcherOpen : ""
            )}
          >
            {filteredMenuItems &&
              filteredMenuItems.map((item, index) => {
                return (
                  <MenuItem
                    className={cx(
                      styles.headerMenuItem,
                      "hide-max-medium",
                      index === filteredMenuItems.length - 1
                        ? styles.spaceAfter
                        : null
                    )}
                    item={item}
                    key={item._key}
                    activeSubmenu={activeSubmenu}
                    onMouseEnter={(event: MouseEvent) =>
                      handleClick(event, item)
                    }
                    onMouseLeave={(event: MouseEvent) =>
                      handleClick(event, item)
                    }
                    clickHandler={(event: MouseEvent) =>
                      handleClick(event, item)
                    }
                  >
                    <span className="underline-on-hover">{item.title}</span>
                  </MenuItem>
                );
              })}

            <li className={cx(styles.headerMenuItem, "hide-min-large")}>
              <button onClick={() => handleMenuToggle()}>
                {menuIsOpen ? (
                  <span className="underline-on-hover">X Close</span>
                ) : (
                  <span className="underline-on-hover">Menu</span>
                )}
              </button>
            </li>
            {/* Currently hide currency switcher */}
            {/* <li className={styles.headerMenuItem}>
              <button
                className={cx(currencySwitcherOpen ? "active-button" : "")}
                onClick={() => handleCurrencyClick()}
              >
                <span className="underline-on-hover">
                  {activeCurrency} {CURRENCY_SYMBOLS[activeCurrency]}
                </span>
              </button>
            </li> */}
            {helpItem && (
              <MenuItem
                className={cx(styles.headerMenuItem, "hide-max-medium")}
                item={helpItem}
                activeSubmenu={activeSubmenu}
                onMouseEnter={(event: MouseEvent) =>
                  handleClick(event, helpItem)
                }
                onMouseLeave={(event: MouseEvent) =>
                  handleClick(event, helpItem)
                }
                clickHandler={(event: MouseEvent) =>
                  handleClick(event, helpItem)
                }
              >
                <span className="underline-on-hover">{helpItem.title}</span>
              </MenuItem>
            )}
            <li className={styles.headerMenuItem}>
              <Link to="/account" type="internalLink">
                Account
              </Link>
            </li>
            <li className={styles.headerMenuItem}>
              <button
                className={cx(
                  styles.cartToggle,
                  cartIsOpen ? "active-button" : ""
                )}
                onClick={() => handleCartToggle()}
              >
                <span>
                  <span className="underline-on-hover">Bag</span>
                  {count > 0 && (
                    <>
                      {" "}
                      <span className={styles.cartCount}>({count})</span>
                    </>
                  )}
                </span>
              </button>
            </li>
          </ul>
        </div>
        <MobileMenu menuItems={menuItems} />
      </div>
      <Submenu menu={activeSubmenu} clickHandler={handleClick} />
      <CurrencySwitcher
        open={currencySwitcherOpen}
        toggle={toggleCurrencySwitcher}
      />
    </div>
  );
};
