import React, { useState } from "react";
import cx from "classnames";
import { Modal, Fade } from "@material-ui/core";
import { MenuItem } from "src/components/menu-item";
import { Backdrop } from "src/components/backdrop";
import { useStoreContext, useToggleSubmenu } from "src/context/siteContext";
import { getProductCount } from "src/utils/getProductCount";

import * as styles from "./submenu.module.css";

interface SubmenuProps {
  clickHandler?: (event: MouseEvent, item: any) => void;
  menu?: {
    sections: [
      {
        _key: string;
        title: string;
        items: [
          {
            _key: string;
            title: string;
            type: string;
            internalLink?: {
              link: {};
            };
          }
        ];
      }
    ];
  };
}

export const Submenu = ({ clickHandler, menu }: SubmenuProps) => {
  const { noticeBarVisible, submenuIsOpen } = useStoreContext();
  const toggleSubmenu = useToggleSubmenu();
  return (
    <>
      <Modal
        anchor="right"
        open={submenuIsOpen}
        onClose={() => toggleSubmenu()}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ active: submenuIsOpen, toggle: toggleSubmenu }}
      >
        <Fade
          in={submenuIsOpen}
          timeout={{
            enter: 600,
            exit: 600,
          }}
        >
          <div
            className={cx(
              styles.submenu,
              noticeBarVisible ? styles.noticeBarVisible : ""
            )}
            role="presentation"
          >
            <div className={styles.inner}>
              <nav>
                {menu &&
                  menu.sections.map((section) => {
                    return (
                      <ul
                        key={section._key}
                        className={cx(styles.menuSection)}
                        role="menu"
                      >
                        <li
                          className={cx(
                            styles.menuItem,
                            styles.menuSectionTitle
                          )}
                        >
                          {section.title}
                        </li>
                        {section.items.map((item) => {
                          const productCount = getProductCount(item);
                          return (
                            <MenuItem
                              className={cx(styles.menuItem)}
                              item={item}
                              key={item._key}
                              clickHandler={(event) =>
                                clickHandler(event, item)
                              }
                            >
                              <span className="underline-on-hover">
                                {item.title}
                              </span>
                              {productCount ? (
                                <span className={styles.productCount}>
                                  ({productCount})
                                </span>
                              ) : (
                                ""
                              )}
                            </MenuItem>
                          );
                        })}
                      </ul>
                    );
                  })}
              </nav>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
