import React, { useState } from "react";
import cx from "classnames";
import { Modal, Fade } from "@material-ui/core";
import { Backdrop } from "src/components/backdrop";
import Div100vh from "src/components/div100vh";
import { useStoreContext, useToggleNewsletter } from "src/context/siteContext";
import { Signup } from "src/components/signup";

import * as styles from "./newsletter.module.css";

export const Newsletter = () => {
  const { newsletterIsOpen, noticeBarVisible } = useStoreContext();
  const toggleNewsletter = useToggleNewsletter();
  return (
    <>
      <Modal
        anchor="left"
        open={newsletterIsOpen}
        onClose={() => toggleNewsletter()}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ active: newsletterIsOpen, toggle: toggleNewsletter }}
      >
        <Fade
          in={newsletterIsOpen}
          timeout={{
            enter: 600,
            exit: 600,
          }}
        >
          <Div100vh
            className={cx(
              styles.newsletter,
              noticeBarVisible ? styles.noticeBarVisible : ""
            )}
            role="presentation"
          >
            <div className={cx(styles.inner)}>
              <button className={styles.closeButton} onClick={toggleNewsletter}>
                X <span className="underline-on-hover">Close</span>
              </button>
              <Signup
                className={styles.signup}
                messageClassName={styles.signupMessage}
              />
            </div>
          </Div100vh>
        </Fade>
      </Modal>
    </>
  );
};
