import React, { VoidFunctionComponent } from "react";
import cx from "classnames";
import { Link } from "../link";
import {
  useStoreContext,
  useToggleSubmenu,
  useToggleCart,
  useToggleNewsletter,
} from "src/context/siteContext";

import styles from "./menu-item.module.css";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";

interface MenuItemProps {
  className?: string;
  activeClassName?: string;
  item: {
    type: string;
    title: string;
    internalLink?: {
      link: {};
    };
    externalLink?: {
      link: string;
      targetBlank: boolean;
    };
    submenu?: {
      id: string;
    };
    spaceAfter?: boolean;
  };
  ariaLabel?: string;
  clickHandler?: (event: MouseEvent, item: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
  children?: any;
  activeSubmenu?: {
    id: string;
  };
}

export const MenuItem = ({
  className,
  activeClassName = "active",
  item,
  ariaLabel,
  clickHandler,
  onMouseEnter,
  onMouseLeave,
  onMouseOver,
  children,
  activeSubmenu,
}: MenuItemProps) => {
  const { newsletterIsOpen } = useStoreContext();

  switch (item.type) {
    case "internalLink":
    case "externalLink":
      return (
        <li className={cx(styles.menuItem, className)}>
          <Link
            type={item.type}
            activeClassName={activeClassName}
            onClick={(event) => clickHandler && clickHandler(event, item)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
            link={item && item}
            aria-label={ariaLabel}
          >
            {children}
          </Link>
        </li>
      );
    case "submenu":
      return (
        <li className={cx(styles.menuItem, className)}>
          <button
            className={
              activeSubmenu &&
              item.submenu &&
              activeSubmenu.id === item.submenu.id
                ? "active-button"
                : ""
            }
            onClick={(event) => clickHandler && clickHandler(event, item)}
          >
            {children}
          </button>
        </li>
      );
    case "newsletter":
      return (
        <li className={cx(styles.menuItem, className)}>
          <button
            className={newsletterIsOpen ? "active-button" : ""}
            onClick={(event) => clickHandler && clickHandler(event, item)}
          >
            {children}
          </button>
        </li>
      );
    case "zendesk":
      return (
        <li className={cx(styles.menuItem, className)}>
          <button
            onClick={(event) => clickHandler && clickHandler(event, item)}
          >
            {children}
          </button>
        </li>
      );

    default:
      return <span>Yo</span>;
  }
};
