import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useRemoveItemFromCart, client } from "src/context/siteContext";
import { Image } from "src/components/image";
import { ProductTitle } from "src/components/product-title";
import { Price } from "src/components/price";

import styles from "./line-item.module.css";

export const LineItem = ({
  id,
  title,
  quantity,
  variant,
  customAttributes,
}: {
  id: string;
  title: string;
  quantity: number;
  variant: {
    title: string;
    presentmentPrices: any;
    price: string;
    compareAtPrice: string;
    image: string;
    selectedOptions: [
      {
        name: string;
        value: string;
      }
    ];
  };
  customAttributes: Array<{
    value: string;
  }>;
}) => {
  const removeFromCart = useRemoveItemFromCart();
  const [stateQuantity, setQuantity] = useState(quantity);

  const {
    title: variantTitle,
    presentmentPrices,
    price,
    compareAtPrice,
    image,
    selectedOptions,
  } = variant;

  const itemImage =
    image &&
    client.image.helpers.imageForSize(image, {
      maxWidth: 352,
      maxHeight: 526,
    });

  return (
    <div className={styles.lineItem}>
      <div className={styles.thumbnail}>
        {itemImage && (
          <Image
            className={styles.thumbnailImage}
            src={itemImage}
            alt={title}
            style={{
              objectFit: "cover",
              height: "100%",
            }}
          />
        )}
      </div>
      <div className={styles.details}>
        <ProductTitle tag="p" className={styles.productTitle} title={title} />
        {selectedOptions?.length > 0 &&
          selectedOptions.map((option) => {
            if (
              option.name === "Title" ||
              option.name === "Lens" ||
              option.name === "Color"
            ) {
              return;
            }
            return (
              <p key={option.name}>
                {option.name}: {option.value}
              </p>
            );
          })}
        <p>Quantity: {stateQuantity}</p>
        <button
          type="reset"
          className={cx("reset", styles.remove)}
          onClick={() => removeFromCart(id)}
        >
          <span className="underline-on-hover">Remove</span>
        </button>
      </div>
      <div className={styles.pricing}>
        <span className={cx(styles.price)}>
          ${Number(price.amount) * stateQuantity}
          {/* <Price
            defaultPrice={price}
            presentmentPrices={presentmentPrices}
            multiplier={stateQuantity}
            variantId={Number(id)}
          /> */}
        </span>
      </div>
    </div>
  );
};
