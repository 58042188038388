import React, { useState } from "react";
import cx from "classnames";
import { Modal, Fade } from "@material-ui/core";
import { Backdrop } from "src/components/backdrop";
import {
  useActiveCurrency,
  useSetCurrency,
  useStoreContext,
} from "src/context/siteContext";

import styles from "./currency-switcher.module.css";

export const CURRENCY_SYMBOLS = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

export const CurrencySwitcher = ({
  open,
  toggle,
}: {
  open: boolean;
  toggle: () => void;
}) => {
  const { noticeBarVisible, enabledCurrencies } = useStoreContext();
  const activeCurrency = useActiveCurrency();
  const setCurrency = useSetCurrency();

  const handleClick = (currency: string) => {
    setCurrency(currency);
    toggle();
  };

  return (
    <>
      <Modal
        anchor="right"
        open={open}
        onClose={() => toggle()}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ active: open, toggle: toggle }}
      >
        <Fade
          in={open}
          timeout={{
            enter: 600,
            exit: 600,
          }}
        >
          <div
            className={cx(
              styles.submenu,
              noticeBarVisible ? styles.noticeBarVisible : ""
            )}
            role="presentation"
          >
            <div className={styles.inner}>
              <ul className={cx(styles.menuSection)}>
                {enabledCurrencies.map((currency) => (
                  <li className={styles.menuItem} key={currency}>
                    <button
                      className={cx(
                        activeCurrency === currency ? "active-button" : ""
                      )}
                      onClick={() => handleClick(currency)}
                    >
                      <span className="underline-on-hover">
                        {currency} {CURRENCY_SYMBOLS[currency]}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
