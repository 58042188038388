import React from "react";
import cx from "classnames";
import { Modal, Fade } from "@material-ui/core";
import Div100vh from "src/components/div100vh";

import { Backdrop } from "src/components/backdrop";
import { CartView } from "src/components/cart/view";
import { useStoreContext, useToggleCart } from "src/context/siteContext";

import styles from "./drawer.module.css";

export const CartDrawer = () => {
  const { cartIsOpen, noticeBarVisible } = useStoreContext();
  const toggleCart = useToggleCart();

  return (
    <Modal
      open={cartIsOpen}
      onClose={() => toggleCart()}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{ active: cartIsOpen, toggle: toggleCart }}
      // style={{ zIndex: 99999 }}
    >
      <Fade
        in={cartIsOpen}
        timeout={{
          enter: 400,
          exit: 300,
        }}
      >
        <Div100vh
          className={cx(
            styles.cartDrawer,
            noticeBarVisible ? styles.noticeBarVisible : ""
          )}
        >
          <div className={cx(styles.cartViewContainer)}>
            <CartView />
          </div>
        </Div100vh>
      </Fade>
    </Modal>
  );
};
