import { useStaticQuery, graphql } from "gatsby";
export const useHeaderData = () => {
  const { sanityHeader } = useStaticQuery(
    graphql`
      query HeaderQuery {
        sanityHeader {
          menu: _rawMenu(resolveReferences: { maxDepth: 18 })
          showNoticeBar
          noticeBarCopy
        }
      }
    `
  );

  return sanityHeader;
};
