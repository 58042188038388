/*
  Orginal Author of this file: https://github.com/thetrevorharmon
  Orginal File: https://github.com/thetrevorharmon/sell-things-fast/blob/master/src/context/StoreContext.js

  TYPED out by Issac: https://gist.github.com/isaac-martin

  Extended by Kevin Green for ✨
*/

import React, { useState, useEffect, useContext } from "react";
import { Checkout } from "shopify-storefront-api-typings";
import ShopifyClient from "shopify-buy";
import cookie from "js-cookie";
import { encode } from "shopify-gid";

const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify_checkout_id";
const SHOPIFY_CHECKOUT_CURRENCY = "shopify_checkout_currency";

const client = ShopifyClient.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STORE,
});

interface InitialStore {
  client: ShopifyClient;
  isAdding: boolean;
  cartIsOpen: boolean;
  menuIsOpen: boolean;
  submenuIsOpen: boolean;
  activeSubmenu: {} | undefined;
  newsletterIsOpen: boolean;
  noticeBarVisible: boolean;
  noticeBarDismissed: boolean;
  page: undefined;
  orders: any[];
  customerEmail: string | undefined;
  customerName: string | undefined;
  customerAddress: any;
  customerCourses: any[];
  customerZodiac: string | undefined;
  customerToken: string | undefined;
  checkout: Checkout;
  enabledCurrencies: [string];
  isMediaPlayerOpen: boolean;
  courseMediaType: string | undefined;
  courseMediaFiles: any[];
  currentTrack: any;
}

const initialStoreState = {
  client,
  isAdding: false,
  cartIsOpen: false,
  menuIsOpen: false,
  submenuIsOpen: false,
  activeSubmenu: undefined,
  newsletterIsOpen: false,
  noticeBarVisible: false,
  noticeBarDismissed: false,
  page: undefined,
  customerEmail: undefined,
  customerName: undefined,
  customerAddress: undefined,
  customerCourses: [],
  customerZodiac: undefined,
  customerToken: undefined,
  orders: [],
  checkout: {
    lineItems: [],
  } as Checkout,
  enabledCurrencies: ["USD"],
  isMediaPlayerOpen: false,
  courseMediaFiles: [],
  courseMediaType: undefined,
  currentTrack: undefined
};

const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null,
});

const StoreContextProvider = ({ children }: { children: any }) => {
  const [store, setStore] = useState(initialStoreState);
  const [initStore, setInitStore] = useState(false);

  useEffect(() => {
    if (initStore === false) {
      const initializeCheckout = async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== "undefined";
        const existingCheckoutId = isBrowser
          ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
          : null;

        if (existingCheckoutId) {
          try {
            const checkout = await fetchCheckout(store, existingCheckoutId);

            // Make sure this cart hasn’t already been purchased.
            if (!checkout.completedAt) {
              setCheckoutInState(checkout, setStore);
              return;
            }
          } catch (e) {
            localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null);
            localStorage.setItem(SHOPIFY_CHECKOUT_CURRENCY, null);
          }
        }

        const newCheckout = await createNewCheckout(store);
        setCheckoutInState(newCheckout, setStore);
      };

      initCustomer(setStore);
      initializeCheckout();
      setEnabledCurrenciesInState(store, setStore);
      setInitStore(true);
    }
  }, [store, setStore, store.client.checkout, initStore]);

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStoreContext() {
  const { store } = useContext(StoreContext);
  return store;
}

const createNewCheckout = (store: InitialStore): Checkout => {
  return store.client.checkout.create({
    presentmentCurrencyCode: store.currency,
  });
};

const fetchCheckout = (store: InitialStore, id: string): Checkout => {
  return store.client.checkout.fetch(id);
};

const setCheckoutInState = (checkout: Checkout, setStore: any) => {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id);
    localStorage.setItem(SHOPIFY_CHECKOUT_CURRENCY, checkout.currencyCode);
  }

  setStore((prevState: InitialStore) => {
    return { ...prevState, checkout };
  });
};

const setEnabledCurrenciesInState = (store: InitialStore, setStore: any) => {
  store.client.shop.fetchInfo().then((shop) => {
    const enabledCurrencies = shop.paymentSettings.enabledPresentmentCurrencies.map(
      (item: any) => item.key
    );
    setStore((prevState: InitialStore) => {
      return { ...prevState, enabledCurrencies };
    });
  });
};

const initCustomer = (setStore: any) => {
  const customerEmail = cookie.get("customer_email");
  const customerToken = cookie.get("customer_token");
  const customerName = cookie.get("customer_firstName");
  const customerAddress = cookie.get("customer_defaultAddress");
  const customerCourses = cookie.get("customer_courses");
  const customerZodiac = cookie.get("customer_zodiac");

  if (customerEmail && customerToken && customerName) {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        customerEmail,
        customerToken,
        customerName,
        customerAddress: customerAddress ? JSON.parse(customerAddress): undefined,
        customerCourses: customerCourses ? JSON.parse(customerCourses): [],
        customerZodiac,
      };
    });
  }
};

const setCustomerInState = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  async function updateCustomerInState() {
    const customerEmail = cookie.get("customer_email");
    const customerToken = cookie.get("customer_token");
    const customerName = cookie.get("customer_firstName");
    const customerAddress = cookie.get("customer_defaultAddress");
    const customerCourses = cookie.get("customer_courses")
    const customerZodiac = cookie.get("customer_zodiac")

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        customerEmail,
        customerToken,
        customerName,
        customerAddress: customerAddress ? JSON.parse(customerAddress): undefined,
        customerCourses: customerCourses ? JSON.parse(customerCourses) : [],
        customerZodiac,
       };
    });
  }

  return updateCustomerInState;
};

function useActiveCurrency() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return checkout.currencyCode;
}

function useCartCount() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  let count = 0;
  if (checkout.lineItems) {
    count = checkout.lineItems.reduce(
      (runningTotal: number, item: any) => item.quantity + runningTotal,
      0
    );
  }

  return count;
}

function useCartTotals() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const subtotal = checkout.subtotalPriceV2
    ? `${Number(checkout.subtotalPriceV2.amount).toFixed(2)}`
    : "-";
  const tax = checkout.totalTaxV2
    ? `${Number(checkout.totalTaxV2.amount).toFixed(2)}`
    : "-";
  const total = checkout.totalPriceV2
    ? `${Number(checkout.totalPriceV2.amount).toFixed(2)}`
    : "-";

  return {
    subtotal,
    tax,
    total,
  };
}

function useCartItems() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return checkout.lineItems;
}

function useCustomer() {
  const {
    store: { customerEmail, customerName, customerToken, customerCourses, customerZodiac },
  } = useContext(StoreContext);

  return { customerEmail, customerName, customerToken, customerCourses, customerZodiac };
}

async function useProductLookup(variantId: any) {
  // TODO: Figure out looking up product by currency
  const {
    store: { client },
  }: { store: InitialStore } = useContext(StoreContext);

  if (!variantId) {
    console.error("variant id is required.");
    return;
  }

  const productId = `gid://shopify/ProductVariant/${variantId}`;

  const allProducts = await client.product.fetchAll(250).then((products) => {
    return products;
  });

  const filteredProduct = allProducts.filter(
    (product) => product?.variants[0]?.id === productId
  );

  console.log("first productprice", allProducts[0]?.variants[0]?.price);
  return true;
}

function useAddItemToCart() {
  const {
    store: { checkout, client },
    setStore,
  }: { store: InitialStore; setStore: any } = useContext(StoreContext);

  async function addItemToCart(
    variant: any,
    quantity: number,
    attributes?: []
  ) {
    if (!variant.id || !quantity) {
      console.error("variant id and quantity are required.");
      return;
    }

    setStore((prevState: InitialStore) => {
      return { ...prevState, isAdding: true };
    });

    const checkoutId = checkout.id;

    const lineItemsToAdd = [
      { variantId: variant.id, quantity, customAttributes: attributes },
    ];

    const newCheckout = await client.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    );

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        checkout: newCheckout,
        cartIsOpen: true,
        isAdding: false,
      };
    });
  }

  return addItemToCart;
}

function useRemoveItemFromCart() {
  const {
    store: { checkout },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function removeItemFromCart(itemId: string) {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      itemId,
    ]);

    setStore((prevState: InitialStore) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return removeItemFromCart;
}

function useUpdateItemsFromCart() {
  const {
    store: { checkout },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function updateItemsFromCart(items: any) {
    items = [].concat(items);
    const newCheckout = await client.checkout.updateLineItems(
      checkout.id,
      items
    );

    setStore((prevState: InitialStore) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return updateItemsFromCart;
}

function useCheckout() {
  const {
    store: { checkout },
  }: {
    store: InitialStore;
  } = useContext(StoreContext);
  return () => {
    // @ts-ignore
    window.analytics && window.analytics.initiateCheckout();

    window.location.href = checkout.webUrl;
  };
}

function useSetPage() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);
  async function setPage(page: string) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, page };
    });
  }
  return setPage;
}

function useToggleCart() {
  const {
    store: { cartIsOpen },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function toggleCart() {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        cartIsOpen: !cartIsOpen,
      };
    });
  }

  return toggleCart;
}

function useToggleMenu() {
  const {
    store: { menuIsOpen },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  function toggleMenu() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, menuIsOpen: !menuIsOpen };
    });
  }

  return toggleMenu;
}

function useToggleSubmenu() {
  const {
    store: { submenuIsOpen },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  function toggleSubmenu() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, submenuIsOpen: !submenuIsOpen };
    });
  }

  return toggleSubmenu;
}

function useSetActiveSubmenu() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);

  async function setActiveSubmenu(submenu: {} | undefined) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, activeSubmenu: submenu };
    });
  }

  return setActiveSubmenu;
}

function useToggleNewsletter() {
  const {
    store: { newsletterIsOpen },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  function toggleNewsletter() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, newsletterIsOpen: !newsletterIsOpen };
    });
  }

  return toggleNewsletter;
}

function useRevealNoticeBar() {
  const {
    store,
    setStore,
  }: {
    store;
    setStore: any;
  } = useContext(StoreContext);

  const { noticeBarVisible } = store;

  function revealNoticeBar() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, noticeBarVisible: !noticeBarVisible };
    });
  }

  return revealNoticeBar;
}

function useHideNoticeBar() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);

  function hideNoticeBar() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, noticeBarVisible: false };
    });
  }

  return hideNoticeBar;
}

function useDismissNoticeBar() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);

  function dismissNoticeBar() {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        noticeBarVisible: false,
        noticeBarDismissed: true,
      };
    });
  }

  return dismissNoticeBar;
}

function useSetCurrency() {
  const {
    store,
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function setCurrency(currency: string) {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        activeCurrency: currency,
      };
    });

    const existingLineItems = store.checkout.lineItems.map((item: any) => {
      return {
        variantId: item.variant.id,
        quantity: item.quantity,
      };
    });

    const newCheckout = await store.client.checkout.create({
      lineItems: existingLineItems,
      presentmentCurrencyCode: currency,
    });
    setCheckoutInState(newCheckout, setStore);
  }

  return setCurrency;
}

function useToggleMediaPlayer() {
  const {
    store,
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function setMediaPlayerOpen(value: boolean) {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        isMediaPlayerOpen: value,
      };
    });
  }

  return setMediaPlayerOpen;
}

function useSetCourseList() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);

  async function setCourses(customerCourses: any[]) {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        customerCourses,
      };
    });
  }

  return setCourses;
}

function useSetCourseMedia() {
  const {
    store,
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function setCourseMedia(
    courseMediaType: string,
    courseMediaFiles: any[]
  ) {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        courseMediaType,
        courseMediaFiles,
      };
    });
  }

  return setCourseMedia;
}

function useSetCurrentTrack() {
  const {
    store,
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function setCurrentTrack(currentTrack: any) {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        currentTrack,
      };
    });
  }

  return setCurrentTrack;
}

export {
  client,
  StoreContextProvider,
  setCustomerInState,
  useAddItemToCart,
  useStoreContext,
  useCustomer,
  useCartCount,
  useCartItems,
  useCartTotals,
  useSetPage,
  useProductLookup,
  useRemoveItemFromCart,
  useUpdateItemsFromCart,
  useCheckout,
  useToggleCart,
  useToggleMenu,
  useToggleSubmenu,
  useSetActiveSubmenu,
  useToggleNewsletter,
  useRevealNoticeBar,
  useHideNoticeBar,
  useDismissNoticeBar,
  useSetCurrency,
  useActiveCurrency,
  useToggleMediaPlayer,
  useSetCourseMedia,
  useSetCourseList,
  useSetCurrentTrack,
};
