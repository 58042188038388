import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import cx from "classnames";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import Analytics from "src/components/analytics";
import { SwitchTransition, Transition } from "react-transition-group";
import { Header } from "src/components/header";
import { Footer, FooterLogo } from "src/components/footer";
import { CartDrawer } from "src/components/cart/drawer";
import { Newsletter } from "src/components/newsletter";
import NewsletterPopup from "src/components/newsletter-popup/NewsletterPopup";

import "svbstrate/dist/svbstrate.css";
import "src/styles/main.css";

interface LayoutProps {
  children: any;
  location: {
    action: string;
    pathname: string;
  };
}

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease`,
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

const Layout = ({ children, location }: LayoutProps) => {
  const [isClient, setClient] = useState(false);
  const [enableFocusOutlines, setEnableFocusOutlines] = useState(false);

  useEffect(() => {
    setClient(true);
    window && window.addEventListener("keydown", handleKeydown);
  }, []);

  const key = isClient ? `client` : `server`;

  const handleKeydown = (e: KeyboardEvent) => {
    // Detect a keyboard user from a tab key press
    const isTabEvent = e.key === "Tab" || e.keyCode === 9;

    if (isTabEvent) {
      setEnableFocusOutlines(true);
    }
  };

  return (
    <>
      <SkipNavLink />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=4894314&fmt=gif"
        />
      </noscript>
      <Helmet
        title="District Vision"
        htmlAttributes={{
          class: cx(enableFocusOutlines ? "" : "no-outline-on-focus"),
        }}
      />
      <Analytics
        facebookPixelId={process.env.GATSBY_FB_PIXEL_ID}
        klaviyoId="Uj8avs"
      />

      <div>
        <Header />
        <CartDrawer />
        <NewsletterPopup />
        <Newsletter />
        <SkipNavContent />
        <SwitchTransition>
          <Transition
            key={location.pathname}
            mountOnEnter
            unmountOnExit
            appear
            timeout={TRANSITION_DURATION}
          >
            {(status) => (
              <div
                className="page-container"
                key={key}
                style={{
                  ...TRANSITION_STYLES.default,
                  ...TRANSITION_STYLES[status],
                }}
              >
                <div className="page-content">
                  {children}
                  <FooterLogo />
                </div>
                <Footer />
              </div>
            )}
          </Transition>
        </SwitchTransition>
      </div>
    </>
  );
};

export default Layout;
