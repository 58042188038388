export const getProductCount = (item: any) => {
  const { internalLink } = item;
  let productCount = 0;

  if (internalLink && internalLink.section) {
    if (internalLink.section.collections) {
      internalLink.section.collections.map((collection: { products: [] }) => {
        if (collection && collection.products) {
          productCount += collection.products.length;
        }
      });
      return productCount;
    } else if (internalLink.section.products) {
      return internalLink.section.products.length;
    }
  } else if (
    internalLink &&
    internalLink.link &&
    internalLink.link._type === "productLandingPage"
  ) {
    internalLink.link.content.main.categories.map(
      (category: { collections: [] }) => {
        category &&
          category.collections &&
          category.collections.map((collection: { products: [] }) => {
            if (collection && collection.products) {
              productCount += collection.products.length;
            }
          });
      }
    );

    return productCount;
  }

  return productCount;
};
